import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="popup"
export default class extends Controller {
  initialize() {}

  connect() {
    // console.log("Hello from popup controller");
    window.addEventListener("click", function (event) {
      if (event.target.className === "modal") {
        const modal = event.target;
        modal.style.opacity = "0";
        modal.classList.add("pointer-events-none");
        const body = document.querySelector("body");
        body.classList.remove("overflow-hidden");
      }
    });
  }

  show(event) {
    const modal = event.target.nextElementSibling;
    this.openModal(modal);
  }

  hide(event) {
    const modal = event.target.closest(".modal");
    this.closeModal(modal);
  }

  disconnect() {}

  openModal(modal) {
    modal.style.opacity = "1";
    modal.classList.remove("pointer-events-none");
    const body = document.querySelector("body");
    body.classList.add("overflow-hidden");
  }

  closeModal(modal) {
    modal.style.opacity = "0";
    modal.classList.add("pointer-events-none");
    const body = document.querySelector("body");
    body.classList.remove("overflow-hidden");
  }
}
