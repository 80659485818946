import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="highlight-navbar"
export default class extends Controller {
  connect() {
    $(function () {
      $("a").each(function () {
        if (window.location.href.includes($(this).prop("href"))) {
          $(this).addClass("current");
        }
      });
    });
  }
}
