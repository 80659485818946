import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="switch-teacher"
export default class extends Controller {
  connect() {
    const windowWidth = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--window-width", `${window.innerWidth}px`);
    };
    window.addEventListener("resize", windowWidth);
    windowWidth();

    const teaching = document.querySelectorAll(".teaching");
    teaching.forEach(function (item) {
      item.firstElementChild
        .querySelector(".swipe-left")
        .classList.add("opacity-10", "first");
      item.lastElementChild
        .querySelector(".swipe-right")
        .classList.add("opacity-10", "last");
    });

    const swipeRight = document.querySelectorAll(".swipe-right");
    swipeRight.forEach(function (item) {
      item.addEventListener("click", function () {
        if (item.classList.contains("last") === false) {
          item.closest(".teaching").scrollBy(window.innerWidth, 0);
        }
      });
    });

    const swipeLeft = document.querySelectorAll(".swipe-left");
    swipeLeft.forEach(function (item) {
      item.addEventListener("click", function () {
        if (item.classList.contains("first") === false) {
          item.closest(".teaching").scrollBy(0 - window.innerWidth, 0);
        }
      });
    });
  }
}
