import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="member-render"
export default class extends Controller {
  connect() {
    const coreTeam = document.querySelector('.core-team');

    const members = [
      {
        name: 'Tung Doan',
        role: 'AI Project Lead'
      },
      {
        name: 'An Phan',
        role: 'Project Manager'
      },
      {
        name: 'Son Huynh',
        role: 'Senior Research Engineer'
      },
      {
        name: 'An Nguyen',
        role: 'Research Engineer'
      },
      {
        name: 'Khanh Tran',
        role: 'Research Engineer'
      },
      {
        name: 'An Le',
        role: 'Research Engineer'
      }
    ]

    const renderMembers = () => {
      const htmls = members.map((member, index) => {
        return `
          <div class="w-50 flex-shrink-0 snap-start">
            <div class="w-50 h-50 rounded-sm bg-cover bg-center" style="background-image: url('/app/assets/images/our_teams/CoreMember_${member.name}') ;"></div>
            <div class="text-center mt-4">
              <h2 class="text-h2 mb-[5px]">${member.name}</h2>
              <p class="text-h3">${member.role}</p>
            </div>
          </div>
        `
      })
      coreTeam.innerHTML = htmls.join('');
    }

    renderMembers()
  }
}
