import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="get-device-height"
export default class extends Controller {
  connect() {
    // Height on Iphone Safari
    const windowHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--window-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', windowHeight);
    windowHeight();
  }
}
