import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="menu"
export default class extends Controller {
  connect() {
    // console.log("Hello from menu_controller");
    const menuToggle = document.querySelector(".menu-toggle");
    // console.log("menuToggle: ", menuToggle);
    window.addEventListener("DOMContentLoaded", function () {
      window.addEventListener("click", function (event) {
        if (event.target.classList.contains("modal")) {
          const modal = event.target;
          // console.log(modal);
          menuToggle.classList.remove("menu--active");
          modal.classList.replace("opacity-100", "opacity-0");
          modal.classList.add("pointer-events-none");
          const body = document.querySelector("body");
          body.classList.remove("overflow-hidden");

          const menuIcon = menuToggle.querySelector(".menu-icon");

          if (menuIcon !== null) {
            menuIcon.classList.replace("bg-white", "bg-black");
            menuIcon.classList.replace("before:bg-white", "before:bg-black");
            menuIcon.classList.replace("after:bg-white", "after:bg-black");
          }
        }
      });
    });
  }

  show(event) {
    // console.log("Event: ", event.target);
    let menuToggle, menuIcon;
    if (event.target.classList.contains("menu-toggle")) {
      menuToggle = event.target;
      menuIcon = menuToggle.firstElementChild;
    } else {
      menuIcon = event.target;
      menuToggle = menuIcon.parentElement;
    }
    // console.log("menuToggle: ", menuToggle);
    // console.log("menuIcon: ", menuIcon);
    let modal = menuToggle.nextElementSibling;
    // console.log("Modal: ", modal);
    if (menuToggle.classList.contains("menu--active")) {
      this.closeModal(modal);
      menuToggle.classList.remove("menu--active");
      if (menuIcon.classList.contains('menu-icon')) {
        menuIcon.classList.replace("bg-white", "bg-black");
        menuIcon.classList.replace("before:bg-white", "before:bg-black");
        menuIcon.classList.replace("after:bg-white", "after:bg-black");
      }
    } else {
      menuToggle.classList.add("menu--active");
      this.openModal(modal);
      if (menuIcon.classList.contains('menu-icon')) {
        menuIcon.classList.replace("bg-black", "bg-white");
        menuIcon.classList.replace("before:bg-black", "before:bg-white");
        menuIcon.classList.replace("after:bg-black", "after:bg-white");
      }
    }
  }

  openModal(modal) {
    modal.classList.replace("opacity-0", "opacity-100");
    modal.classList.remove("pointer-events-none");
    const body = document.querySelector("body");
    body.classList.add("overflow-hidden");
  }

  closeModal(modal) {
    modal.classList.replace("opacity-100", "opacity-0");
    modal.classList.add("pointer-events-none");
    const body = document.querySelector("body");
    body.classList.remove("overflow-hidden");
  }
}
