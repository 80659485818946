import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="back-to-top"
export default class extends Controller {
  connect() {
    // When the user scrolls down 20px from the top, show the button
    // console.log("Hello from back-to-top controller");
    this.scrollFunction();
    document.addEventListener("scroll", this.scrollFunction);
  }

  scrollFunction() {
    const scrollButton = document.getElementById("scrollButton");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      scrollButton.style.display = "block";
    } else {
      scrollButton.style.display = "none";
    }
  }

  topFunction() {
    setTimeout(function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
}
